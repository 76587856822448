import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import typefaceFont from 'three/examples/fonts/helvetiker_regular.typeface.json'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'

THREE.ColorManagement.enabled = false

/**
 * Base
 */
// Debug
const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('/textures/matcaps/9.png')

/**
 * Fonts
 */
const fontLoader = new FontLoader()

fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) => {
        const textGeometry = new TextGeometry(
            'Mahi Computers',
            {
                font: font,
                size: 0.8,
                height: 0.5,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5
            }
        )
        // textGeometry.computeBoundingBox()
        // textGeometry.translate(
        //     - (textGeometry.boundingBox.max.x - 0.02) * 0.5, // Subtract bevel size
        //     - (textGeometry.boundingBox.max.y - 0.02) * 0.5, // Subtract bevel size
        //     - (textGeometry.boundingBox.max.z - 0.03) * 0.5  // Subtract bevel thickness
        // )
        textGeometry.center()
        const textMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
        //const textMaterial = new THREE.MeshNormalMaterial()
        // textMaterial.wireframe = true
        const text = new THREE.Mesh(textGeometry, textMaterial)
        scene.add(text)

        for(let i = 0; i<1000; i++)
        {
            const sphereGeometry = new THREE.SphereGeometry(0.2,34,24)
            const sphereMaterial = new THREE.MeshNormalMaterial()
            //const sphereMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
            const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial)

            // const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45)
            const donutGeometry = new THREE.BoxGeometry(0.5,0.5,0.5)
            //const donutMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
            const donutMaterial = new THREE.MeshNormalMaterial()
            const donut = new THREE.Mesh(donutGeometry, donutMaterial)

            donut.position.x = (Math.random() - 0.5) * 50
            donut.position.y = (Math.random() - 0.5) * 50
            donut.position.z = (Math.random() - 0.5) * 50

            donut.rotation.x = Math.random() * Math.PI
            donut.rotation.y = Math.random() * Math.PI

            const scale = Math.random() - 0.5
            donut.scale.x = scale
            donut.scale.y = scale
            donut.scale.z = scale

            sphere.position.x = (Math.random() - 0.5) * 30
            sphere.position.y = (Math.random() - 0.5) * 30
            sphere.position.z = (Math.random() - 0.5) * 30

            sphere.rotation.x = Math.random() * Math.PI
            sphere.rotation.y = Math.random() * Math.PI

            //const scale = Math.random() - 0.5
            sphere.scale.x = scale
            sphere.scale.y = scale
            sphere.scale.z = scale

            scene.add(sphere, donut)
        }
    }
)



/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 10
//scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.outputColorSpace = THREE.LinearSRGBColorSpace
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    scene.rotation.y = elapsedTime * 0.05
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()